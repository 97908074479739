import React, { Component, Fragment } from 'react';
import { observer, inject } from 'mobx-react';
import moment from 'moment';
import { Link } from 'react-router-dom';
const base = 'secondary-header';

@inject('LiveDispatchStore', 'UserStore', 'RiderAndOutletPartnerStore')

@observer
class DispatchHeader extends Component {
    handleSetDate = (forceLatest, momentDate) => {
        const {
            LiveDispatchStore: { handleGetDispatches },
        } = this.props;
        const date = moment(momentDate).format('DD-MM-YYYY');

        handleGetDispatches(forceLatest, date);
    };

    handleToggleSwitch = () => {
        const {
            LiveDispatchStore: { setAutoPrint, isAutoPrint },
        } = this.props;
        setAutoPrint(!isAutoPrint);
    };

    handleClickOpenModal = () => {
        const { LiveDispatchStore } = this.props;
        LiveDispatchStore.recentlyClosedAggregatorStores = LiveDispatchStore.closedAggregatorStores;
    }

    render() {
        const {
            UserStore: {
                currentPage,
                handleSetFullscreen,
                isCurrentPageFullScreen,
                selectedHub
            },
            RiderAndOutletPartnerStore
        } = this.props;

        const { staffMembers } = RiderAndOutletPartnerStore;

        return (
            <div className={`${base}__wrapper bg--white`}>
                <div className={`${base} display--flex justify--space-between prl`} style={{paddingLeft: '22px'}}> 
                    <div
                        className={`${base}__left display--flex justify--space-between flex-align-items-center`}
                    >
                        <img src={"https://image.popcontent.link/pop_logo.png"} />
                        <p style={{margin: '0px 30px', fontSize: '25px' }}>
                            {selectedHub && `${selectedHub.pickupAddress.name.split('@')[1]}`} 
                        </p>
                        {staffMembers.map((staffMember) => (
                            <p style={{
                                display: 'flex',
                                padding: '5px 20px',
                                marginLeft: '10px',
                                backgroundColor: '#ffc700',
                                borderRadius: '10px',
                                color: '#7056B5',
                                fontSize: '25px'
                            }}>
                                {staffMember.position == "Outlet Manager" && (
                                    <p style={{ 
                                        marginRight: '5px',
                                        height: '27px',
                                        width: '27px',
                                        backgroundColor: '#7056B5',
                                        color: '#ffc700',
                                        borderRadius: '50%',
                                        fontSize: '20px',
                                        marginTop: '4px'
                                     }}> C </p>
                                )}
                                {staffMember.name}
                            </p>
                        ))}
                    </div>
                    <div className={'display--flex'}>
                        <Link
                            to={{
                                pathname: "/all-orders",
                                state: { from: '/live-dispatch' }
                            }}>
                            <button className={`${base}__view-all-orders mrm`} style={{marginTop: '13px'}}>
                                    View all orders
                                </button>
                        </Link>

                        {(currentPage === 'packing') && (
                            <button
                                className={`${base}__fullscreen_btn`}
                                onClick={() => handleSetFullscreen(!isCurrentPageFullScreen)}
                                style={{marginTop: '13px', height: '54px'}}
                            >
                                <img
                                    className="exit_fullscreen_icon"
                                    src={isCurrentPageFullScreen? "https://image.popcontent.link/exit-full-screen.png" : "https://image.popcontent.link/go_full_screen.png"}
                                    alt="Exit fullscreen"
                                />
                            </button>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default DispatchHeader;
