import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import 'animate.css/animate.min.css';
import 'react-toastify/dist/ReactToastify.css';
const base = 'all-orders';


@observer
class SalePlatformIcon extends Component {
    render() {
        const { order, isModal } = this.props;
        let salePlatform = order.sale_platform;
        if(!salePlatform){
            salePlatform = 'PopMeals'
        }
        if (!salePlatform.includes('GrabFood')
            && !salePlatform.includes('FoodPanda')
            && !salePlatform.includes('ShopeeFood')){
            salePlatform = 'PopMeals'
        }

        return (
            <Fragment>
                {salePlatform.includes('GrabFood') && (
                    <img
                        className={isModal ? `${base}__modal_grab_food_icon` : `${base}__grab_food_icon`}
                        src="https://image.popcontent.link/GrabFood-WhiteBG.svg"
                        alt="grabfood icon"
                    />)}
                {salePlatform.includes('FoodPanda') && (
                    <img
                        className={isModal ? `${base}__modal_food_panda_icon` : `${base}__food_panda_icon`}
                        src="https://image.popcontent.link/FoodPanda-WhiteBG.svg"
                        alt="foodpanda icon"
                    />)}
                {salePlatform.includes('ShopeeFood') && (
                    <img
                    className={isModal ? `${base}__modal_shopee_food_icon` : `${base}__shopee_food_icon`}
                    src="https://image.popcontent.link/ShopeeFood-WhiteBG.svg"
                        alt="shopeefood icon"
                    />)}
                {salePlatform === 'PopMeals' && (
                    order.pick_up_type === 'TAKE_AWAY' ? (
                            <img
                                className={isModal ? `${base}__modal_take_away_icon` : `${base}__take_away_icon`}
                                src="https://image.popcontent.link/takeaway_icon.svg"
                                alt="take away icon"
                            />
                        ) : 
                        order.pick_up_type === 'DINE_IN' ? (
                            <img
                                className={isModal ? `${base}__modal_dine_in_icon` : `${base}__dine_in_icon`}
                                src='https://image.popcontent.link/dish-fork-and-knife+1.svg'
                                alt="dine-in icon"
                            />
                        ) : 
                            order.order_mode === 'PICK_UP' ? (
                                <img
                                    className={isModal ? `${base}__modal_take_away_icon` : `${base}__take_away_icon`}
                                    src="https://image.popcontent.link/takeaway_icon.svg"
                                    alt="take away icon"
                                />
                            ) : (
                                <img
                                    className={isModal ? `${base}__modal_delivery_icon` : `${base}__delivery_icon`}
                                    src='https://image.popcontent.link/delievery_icon_white.png'
                                    alt="delivery icon"
                                />
                        ))}
            </Fragment>
        );
    }
}

export default SalePlatformIcon;
