import React, { Component } from 'react';
import MealImagesSection from './MealImagesSection';

const base = 'dispatch_scan';

class InstaserviceScannerView extends Component {
    render() {
        const {
            scannedItem,
            scannedItemSKU,
            originalItemImageLink,
            itemImage: itemWebCamImage,
        } = this.props;

        return (
            <div className={`${base}__instaservice ${base}__scanner_box_view`}>
                <div className={`${base}__scanner_box_header_row`}
                     style={{ justifyContent: 'space-between', width: '100%'}}>
                    <div>
                        <p className={`${base}__order_type_text text--white`}>
                            INSTASERVICE
                        </p>
                    </div>
                    <div>
                        <p className={`text--white`}
                           style={{ fontFamily: 'Inter', fontSize: '40px', fontWeight: '900'}}>
                            {scannedItemSKU ? scannedItemSKU : '---'}
                        </p>
                    </div>
                </div>
                <MealImagesSection
                    originalItemImageLink={originalItemImageLink}
                    itemWebCamImage={itemWebCamImage}
                />
            </div>
        );
    }
}

export default InstaserviceScannerView;
