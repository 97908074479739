import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';
import WarmerSKUCategory from './WarmerSKUCategory';

const base = 'insta-service';

@inject('ProductionStore')
@observer
class CurrentWarmerStock extends Component {

    render() {
        const { currentWarmerStocks } = this.props;

        const groupedByCategory = currentWarmerStocks.reduce((acc, entry) => {
            const { category } = entry;
            
            if (!acc[category]) {
                acc[category] = [];
            }
            
            acc[category].push(entry);
            
            return acc;
        }, {});

        const categories = Object.keys(groupedByCategory).map(category => ({
            category,
            items: groupedByCategory[category].sort((a, b) => b.quantity - a.quantity)
        })).sort((a, b) => b.items.length - a.items.length); 

        return (
            <div>
                <div
                    className={`${base}__card_header`}
                >
                    <div
                        className='in-warmer'
                    >
                        IN WARMER
                    </div>
                </div>
                <div className={`${base}__card_container`}>
                    {categories &&
                    categories.length > 0 &&
                    categories.map((category) => (
                        <WarmerSKUCategory
                            category={category}
                            key={`${category.category}`}
                            isCurrentStock={false}
                        />
                    ))}
                </div>
            </div>
        );
    }
}

export default CurrentWarmerStock;
