import React, { Component } from 'react';
import MealImagesSection from './MealImagesSection';

const base = 'dispatch_scan';
class TakeawayScannerView extends Component {
    render() {
        const {
            scannedItem,
            originalItemImageLink,
            itemImage: itemWebCamImage,
        } = this.props;

        const takeaway_icon = 'https://image.popcontent.link/takeaway_icon.svg';
        const grabfood_icon = 'https://image.popcontent.link/GrabFood-WhiteBG.svg';
        const foodpanda_icon = 'https://image.popcontent.link/FoodPanda-WhiteBG.svg';
        const shopeefood_icon = 'https://image.popcontent.link/ShopeeFood-WhiteBG.svg';

        let brandName = null;
        let platformName = null;
        if (scannedItem.item.sale_platform) {
            if (scannedItem.item.sale_platform.includes('GrabFood')) {
                if (scannedItem.item.sale_platform.indexOf(' - GrabFood') > -1) {
                    brandName = scannedItem.item.sale_platform.split(' - GrabFood')[0];
                    platformName = "GrabFood";
                }
            } else if (scannedItem.item.sale_platform.includes('FoodPanda')) {
                if (scannedItem.item.sale_platform.indexOf(' - FoodPanda') > -1) {
                    brandName = scannedItem.item.sale_platform.split(' - FoodPanda')[0];
                    platformName = "FoodPanda";
                }
            } else if (scannedItem.item.sale_platform.includes('ShopeeFood')) {
                if (scannedItem.item.sale_platform.indexOf(' - ShopeeFood') > -1) {
                    brandName = scannedItem.item.sale_platform.split(' - ShopeeFood')[0];
                    platformName = "ShopeeFood";
                }
            }
        }

        return (
            <div className={scannedItem.item.sale_platform === 'Pick Up' ? `${base}__takeaway ${base}__scanner_box_view` : brandName ? `${base}__virtual_brand ${base}__scanner_box_view` : `${base}__aggregators ${base}__scanner_box_view`}>
                <div className={`${base}__scanner_box_header_row`}>
                    <div className="icon_order_type">
                    {scannedItem.item.sale_platform.includes('GrabFood') && (
                        <img
                            className="aggregator_icon"
                            src={grabfood_icon}
                            alt="grabfood icon"
                            style={{height: '80px', width: '80px', padding: '8px'}}
                        />)}
                    {scannedItem.item.sale_platform.includes('FoodPanda') && (
                        <img
                            className="aggregator_icon"
                            src={foodpanda_icon}
                            alt="foodpanda icon"
                            style={{height: '80px', width: '80px', padding: '8px'}}
                        />)}
                    {scannedItem.item.sale_platform.includes('ShopeeFood') && (
                        <img
                            className="aggregator_icon"
                            src={shopeefood_icon}
                            alt="shopeefood icon"
                            style={{height: '80px', width: '80px', padding: '8px'}}
                        />)}
                    {scannedItem.item.sale_platform === 'Pick Up' && (
                        <img
                            className="take_away_icon"
                            src={takeaway_icon}
                            alt="take away icon"
                            style={{height: '46px', width: '64px'}}
                        />)}
                    {scannedItem.item.sale_platform === 'Pick Up' ? (
                        <p className={`${base}__order_type_text`}>
                             TAKEAWAY
                        </p>
                    ): (
                        <div className={`${base}__order_type_text text--white`}>
                            {brandName ? <p> {brandName} <br /> {platformName} </p>: <p> {scannedItem.item.sale_platform} </p>}
                        </div>
                    )}
                    </div>
                    <div className="icon_order_type">
                        {scannedItem.item.sale_platform === 'Pick Up' ? (
                                <p className={`${base}__bag_number`}>
                                    BAG {scannedItem.bag_number}
                                </p>
                        ) : (
                            <p className={`${base}__bag_number text--white`}>
                                {scannedItem.aggregator_order_id ? scannedItem.aggregator_order_id : scannedItem.bag_number}
                            </p>
                        )}
                    </div>
                </div>
                <MealImagesSection
                    originalItemImageLink={originalItemImageLink}
                    itemWebCamImage={itemWebCamImage}
                    isVirtualBrand={brandName}
                />
            </div>
        );
    }
}

export default TakeawayScannerView;
