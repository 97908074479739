import React, { Component } from 'react';
import BagImageSection from './BagImageSection';

const base = 'dispatch_scan';

class BagScannerLiveDispatchView extends Component {
    render() {
        const {
            scannedItem,
            itemImage: itemWebCamImage,
        } = this.props;

        return (
            <div className={scannedItem.order_mode == "DELIVERY" ? `${base}__bag ${base}__scanner_box_view` : `${base}__takeaway ${base}__scanner_box_view`}>
                <div className={`${base}__scanner_box_header_row`}>
                {scannedItem.order_mode == "DELIVERY" ? (
                    <div className="icon_order_type">
                        <img
                            className={`${base}__icon`}
                            src="https://image.popcontent.link/delievery_icon_black.png"
                            alt="delievery icon"
                        />
                        <p className={`${base}__order_type_text `}>DELIVERY</p>
                    </div>
                    ) : (
                    <div className="icon_order_type">
                        <img
                            className={`${base}__icon`}
                            src="https://image.popcontent.link/takeaway_icon.svg"
                            alt="take away icon"
                        />
                        <p className={`${base}__order_type_text `}>TAKE AWAY</p>
                    </div>
                    )}

                    <p className={`${base}__bag_number`}>
                        BAG {scannedItem.short_order_id ? scannedItem.short_order_id : scannedItem.pick_up_number}
                    </p>
                </div>
                <BagImageSection
                    itemWebCamImage={itemWebCamImage}
                />
            </div>
        );
    }
}

export default BagScannerLiveDispatchView;
