import {observer} from "mobx-react";
import React, {Component, Fragment} from "react";

@observer
class SalePlatformIcon extends Component {
    render() {
        const { item, isIncomingItems } = this.props;
        let salePlatform = item.platform;

        if(!salePlatform){
            salePlatform = 'PopMeals'
        }
        if (!salePlatform.includes('GrabFood')
            && !salePlatform.includes('FoodPanda')
            && !salePlatform.includes('ShopeeFood')){
            salePlatform = 'PopMeals'
        }

        const delievery_icon = 'https://image.popcontent.link/pop_logo.png';
        const virtual_brand_delivery_icon = 'https://image.popcontent.link/virtual_brand_icon.png';
        const takeaway_icon = 'https://image.popcontent.link/takeaway_icon.svg';
        const dinein_icon = 'https://image.popcontent.link/dish-fork-and-knife+1.svg';

        return (
            <Fragment>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    {(salePlatform.includes('GrabFood') || salePlatform.includes('FoodPanda') || salePlatform.includes('ShopeeFood')) && (
                        <img
                            className="delievery_icon"
                            src={delievery_icon}
                            alt="delievery icon"
                            style={{width: '2.75rem', height: '2.75rem'}}
                        />)}
                    {salePlatform === 'PopMeals' && (
                        !item.pick_up_type ? (
                            item.delivery_by_third_party ? (
                                <img
                                    className="delievery_icon"
                                    src={virtual_brand_delivery_icon}
                                    alt="virtual brand delievery icon"
                                    style={{width: '2.75rem', height: '2.5rem'}}
                                    />
                            ) : (
                                <img
                                className="delievery_icon"
                                src={delievery_icon}
                                alt="delievery icon"
                                style={{width: '2.75rem', height: '2.75rem'}}
                                />  
                            )) : item.pick_up_type === 'TAKE_AWAY' ? (
                            <img
                                className="take_away_icon"
                                src={takeaway_icon}
                                alt="take away icon"
                                style={ isIncomingItems ? {filter: 'invert(1)', width: '1.5rem', height: '2rem'} : {width: '1.5rem', height: '2rem'}}
                            />
                            ) : (
                            <img
                                className="dine_in_icon"
                                src={dinein_icon}
                                alt="dine-in icon"
                                style={isIncomingItems ? {filter: 'invert(1)', width: '3rem', height: '2rem'} : {width: '3rem', height: '2rem'}}
                            />
                    ))}
                </div>
            </Fragment>
        );
    }
}

export default SalePlatformIcon;