import React, { Component } from 'react';

const base = 'dispatch_scan';

class MealImagesSection extends Component {
    render() {
        const { originalItemImageLink, itemWebCamImage, isVirtualBrand } = this.props;
        return (
            <div className={`${base}__meal_images_section`}>
                <div
                    className={`${base}__meal_img_title_container ${base}__expected`}
                >
                    <div className={`${base}__title_container`}>
                        <svg
                            className="checkmark_svg"
                            width="50"
                            height="35"
                            viewBox="0 0 50 35"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M0.356689 15.503L19.2553 34.4017L23.2163 30.4407L4.31765 11.5421L0.356689 15.503Z"
                                fill="#03C875"
                            />
                            <path
                                d="M19.2551 34.4017L49.3571 4.29975L45.3961 0.338791L15.2942 30.4407L19.2551 34.4017Z"
                                fill="#03C875"
                            />
                        </svg>

                        <p className="title">HOW IT SHOULD LOOK</p>
                    </div>
                    {originalItemImageLink ? (
                        <div>
                            <img
                                src={originalItemImageLink}
                                className={`${base}__meal_img`}
                                alt="captured meal image"
                            />
                            {isVirtualBrand && (
                                <p className={`${base}__virtual_brand_info`}>
                                    <b>VIRTUAL BRAND ORDER</b>
                                    <br />
                                    DO NOT USE POP PACKAGING
                                </p>
                            )}
                        </div>
                    ) : (
                        <div className={`${base}__error_state`}>
                            <img
                                className={`image`}
                                src="https://image.popcontent.link/img_not_available.svg"
                                alt="no camera present icon"
                            />
                            <p className="text">
                                Image not available
                                <br />
                                Please upload to admintool
                            </p>
                            {isVirtualBrand && (
                                <p className="virtual_brand_info">
                                    <b>VIRTUAL BRAND ORDER</b>
                                    <br />
                                    DO NOT USE POP PACKAGING
                                </p>
                            )}
                        </div>
                    )}
                </div>
                <div
                    className={`${base}__meal_img_title_container ${base}__capture_img_section `}
                >
                    <div className={`${base}__title_container`}>
                        <p className="title">WHAT YOU MADE (CAMERA)</p>
                    </div>
                    {itemWebCamImage ? (
                        <img
                            src={itemWebCamImage}
                            className={`${base}__meal_img`}
                            alt="captured meal image"
                        />
                    ) : (
                        <div className={`${base}__error_state`}>
                            <img
                                className={`image`}
                                src="https://image.popcontent.link/no_camera_img.svg"
                                alt="no camera present icon"
                            />
                            <p className="text">
                                No image captured
                                <br /> Check webcam
                            </p>
                        </div>
                    )}

                    {itemWebCamImage && (
                        <div className={`${base}__upload_picture_chip`}>
                            <p className="text">Uploading picture</p>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default MealImagesSection;
