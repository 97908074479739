import React, { Component } from 'react';

const base = 'dispatch_scan';

class BagImageSection extends Component {
    render() {
        const {itemWebCamImage } = this.props;
        return (
            <div className={`${base}__meal_images_section`}>
                <div
                    className={`${base}__meal_img_title_container ${base}__capture_img_section `}
                >
                    {itemWebCamImage ? (
                        <img
                            src={itemWebCamImage}
                            className={`${base}__meal_img`}
                            alt="captured bag image"
                        />
                    ) : (
                        <div className={`${base}__error_state`}>
                            <img
                                className={`image`}
                                src="https://image.popcontent.link/no_camera_img.svg"
                                alt="no camera present icon"
                            />
                            <p className="text">
                                No image captured
                                <br /> Check webcam
                            </p>
                        </div>
                    )}

                    {itemWebCamImage && (
                        <div className={`${base}__upload_picture_chip`}>
                            <p className="text">Uploading picture</p>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default BagImageSection;
