// App Config for development profile
export default {
    // Base URL
    baseUrl: 'http://localhost:3000',

    // Hostname for static endpoints
    staticAssets: 'http://localhost:3000',

    // Hostname for API endpoints
    // services: 'http://52.74.100.84',
    services: 'http://localhost:8080',

    // Webhook for tracking picking
    pickingTracking:
        'https://script.google.com/a/dahmakan.com/macros/s/AKfycbx4BAY_XYGa60qc4GUDgsHKcKEPkL3vrBrntdDa8Q/exec',

    warmerStockPlanner: {
        url: 'http://localhost:5000',
        token: '',
    },
};
