import React, { Component } from 'react';
import MealImagesSection from './MealImagesSection';

const base = 'dispatch_scan';

class BagScannerView extends Component {
    render() {
        const {
            scannedItem,
            scannedItemSKU,
            originalItemImageLink,
            itemImage: itemWebCamImage,
        } = this.props;

        return (
            <div className={`${base}__bag ${base}__scanner_box_view`}>
                <div className={`${base}__scanner_box_header_row`}>
                    <div className="icon_order_type">
                        <img
                            className={`${base}__icon`}
                            src="https://image.popcontent.link/delievery_icon_black.png"
                            alt="delievery icon"
                            style={{height: '54px', width: '64px'}}
                        />
                        <p className={`${base}__order_type_text `}>DELIVERY</p>
                    </div>

                    <p className={`${base}__bag_number`}>
                        BAG {scannedItem.bag_number}
                    </p>
                </div>
                <MealImagesSection
                    originalItemImageLink={originalItemImageLink}
                    itemWebCamImage={itemWebCamImage}
                />
            </div>
        );
    }
}

export default BagScannerView;
